import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { useLocale } from "../locale";
import {
  Locale,
  LitterInput,
  LitterInputBase,
} from "../../../../../__generated__/globalTypes";
import { Litters, LittersVariables } from "./__generated__/Litters";
import { Litter, LitterVariables } from "./__generated__/Litter";
import {
  LitterCreate,
  LitterCreateVariables,
} from "./__generated__/LitterCreate";
import {
  LitterUpdate,
  LitterUpdateVariables,
} from "./__generated__/LitterUpdate";
import { LittersRoyalStartQuery } from "./useLittersRoyalStart";

const LitterFragment = gql`
  fragment LitterFragment on Litter {
    id
    type
    dob
    male
    female
    genderUnspecifiedCount
    total
    totalAvailable
    dateAvailable
    availableMale
    availableFemale
    createdAt
    univers {
      name
    }
    breed {
      name
    }
    photos {
      id
      url
    }
    visible
    dateVisibleFrom
    dateVisibleTo
    descriptions {
      language
      value
    }
    pureBreed
    pedigreeFCI
    idFather
    idMother
    originCountry
    originBreeder
    neutered
    chippedTatooed
    vaccinated
    startingPrice
    maxPrice
    identification
    royalStartId
    royalStartSyncAvailable
  }
`;

const LittersQuery = gql`
  query Litters(
    $token: String!
    $locale: Locale!
    $page: Float
    $limit: Float
  ) {
    litters(token: $token, locale: $locale, page: $page, limit: $limit)
      @connection(key: "litters", filter: ["token", "locale"]) {
      result {
        ...LitterFragment
      }
      pageInfo {
        page
        limit
        count
        hasNextPage
      }
      error {
        message
        code
      }
    }
  }
  ${LitterFragment}
`;

export const useLitters = ({
  page,
  limit,
}: {
  page?: number;
  limit?: number;
} = {}) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Litters, LittersVariables>(LittersQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale[locale],
      page,
      limit,
    },
  });

  return {
    ...res,
    data,
    items: data?.litters.result || [],
    pageInfo: data?.litters.pageInfo,
  };
};

const LitterQuery = gql`
  query Litter($token: String!, $locale: Locale!, $id: String!) {
    litter(token: $token, locale: $locale, id: $id) {
      result {
        ...LitterFragment
      }
      error {
        message
        code
      }
    }
  }
  ${LitterFragment}
`;

export const useLitter = ({ id }: { id?: string } = {}) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Litter, LitterVariables>(LitterQuery, {
    skip: !token || !id,
    variables: {
      token,
      locale: Locale[locale],
      id: id || "",
    },
  });

  return {
    ...res,
    data,
    item: data?.litter.result,
  };
};

const LitterCreateMutation = gql`
  mutation LitterCreate(
    $token: String!
    $locale: Locale!
    $input: LitterInput!
  ) {
    litterCreate(token: $token, locale: $locale, input: $input) {
      result {
        ...LitterFragment
      }
      error {
        code
        message
      }
    }
  }
  ${LitterFragment}
`;

export const useLitterCreate = () => {
  const locale = useLocale();
  const token = useUserToken();
  const [litterCreate, result] = useMutation<
    LitterCreate,
    LitterCreateVariables
  >(LitterCreateMutation);

  return {
    ...result,
    error:
      result.error ||
      (result.data &&
        result.data.litterCreate &&
        result.data.litterCreate.error),
    litterCreate: async ({
      numberOfPack,
      ...input
    }: LitterInput & { numberOfPack?: number }) => {
      const resultCreate = await litterCreate({
        variables: {
          token,
          locale: locale as Locale,
          input: {
            ...input,
            male:
              typeof input.male === "string"
                ? parseInt(input.male, 10)
                : input.male,
            female:
              typeof input.female === "string"
                ? parseInt(input.female, 10)
                : input.female,
            availableMale:
              typeof input.availableMale === "string"
                ? parseInt(input.availableMale, 10)
                : input.availableMale,
            availableFemale:
              typeof input.availableFemale === "string"
                ? parseInt(input.availableFemale, 10)
                : input.availableFemale,
          },
        },
        refetchQueries: [
          {
            query: LittersQuery,
            variables: {
              token,
              locale,
            },
          },
          {
            query: LittersRoyalStartQuery,
            variables: {
              token,
              locale,
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      return {
        ...resultCreate,
        error:
          (resultCreate.errors && resultCreate.errors[0]) ||
          resultCreate.data?.litterCreate.error,
        litter: resultCreate.data?.litterCreate.result || undefined,
      };
    },
  };
};

const LitterUpdateMutation = gql`
  mutation LitterUpdate(
    $token: String!
    $id: String!
    $locale: Locale!
    $input: LitterInputBase!
  ) {
    litterUpdate(token: $token, id: $id, locale: $locale, input: $input) {
      result {
        ...LitterFragment
      }
      error {
        code
        message
      }
    }
  }
  ${LitterFragment}
`;

export const useLitterUpdate = () => {
  const locale = useLocale();
  const token = useUserToken();
  const [litterUpdate, result] = useMutation<
    LitterUpdate,
    LitterUpdateVariables
  >(LitterUpdateMutation);

  return {
    ...result,
    error:
      result.error ||
      (result.data &&
        result.data.litterUpdate &&
        result.data.litterUpdate.error),
    litterUpdate: async (id: string, input: LitterInputBase) => {
      const resultUpdate = await litterUpdate({
        variables: {
          token,
          id,
          locale: locale as Locale,
          input: {
            ...input,
            availableMale:
              typeof input.availableMale === "string"
                ? parseInt(input.availableMale, 10)
                : input.availableMale,
            availableFemale:
              typeof input.availableFemale === "string"
                ? parseInt(input.availableFemale, 10)
                : input.availableFemale,
          },
        },
        refetchQueries: [
          {
            query: LittersQuery,
            variables: {
              token,
              locale,
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      return {
        ...resultUpdate,
        error:
          (resultUpdate.errors && resultUpdate.errors[0]) ||
          resultUpdate.data?.litterUpdate.error,
      };
    },
  };
};
