import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLocale } from "../locale";
import { Locale } from "../../../../../__generated__/globalTypes";
import {
  LittersRoyalStart,
  LittersRoyalStartVariables,
} from "./__generated__/LittersRoyalStart";
import { useLitterBreedByCode } from "./useLittersMetadata";

export const LittersRoyalStartQuery = gql`
  query LittersRoyalStart($token: String!, $locale: Locale!) {
    littersRoyalStart(token: $token, locale: $locale) {
      result {
        id
        type
        dob
        breedId
        breedCode
        male
        female
        genderUnspecifiedCount
        availableMale
        availableFemale
        pureBreed
        neutered
        chippedTatooed
        photos {
          url
        }
      }
      error {
        message
      }
    }
  }
`;

export const useLittersRoyalStart = ({ skip }: { skip?: boolean } = {}) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<
    LittersRoyalStart,
    LittersRoyalStartVariables
  >(LittersRoyalStartQuery, {
    skip: skip || !token,
    variables: {
      token,
      locale: Locale[locale],
    },
  });

  return {
    ...res,
    errror: res.error || data?.littersRoyalStart.error,
    data,
    items: data?.littersRoyalStart.result || [],
  };
};

export const useLitterRoyalStart = (id?: string) => {
  const { items, ...result } = useLittersRoyalStart({ skip: !id });
  const litter = items.find(item => item.id === id);
  const { breed, loading: loadingBreeds } = useLitterBreedByCode({
    type: litter?.type,
    code: litter?.breedCode.toString()
  });
  if (litter && breed) {
    litter.breedId = breed.id;
  }
  return {
    ...result,
    loading: result.loading || loadingBreeds,
    litter,
  };
};
