import {
  LittersHeader,
  LittersList,
  LittersListMobile,
  Media,
  Pagination,
  isLitterArchived,
  LittersListIncoming,
  Loader,
} from "@royalcanin-be-partner-portal/ui-kit";
import React from "react";
import { Layout } from "../../components/Layout";
import { CheckPermission } from "../../components/CheckPermission";
import { useLitters } from "../../lib/hooks/useLitters";
import { Litters as ILitters } from "../../lib/hooks/__generated__/Litters";
import { withPage } from "../../lib/withPage";
import { useCurrentPartnerDetails } from "../../lib/hooks/usePartnerDetails";
import { WarningNoHKNumber } from "../../components/WarningNoHKNumber";
import { useLittersRoyalStart } from "../../lib/hooks/useLittersRoyalStart";
import { navigateTo } from "../../lib/LocationProvider";
import { useLitterBreedByCode } from "../../lib/hooks/useLittersMetadata";

const Litters = () => {
  const {
    items,
    pageInfo,
    loading: loadingLitter,
    fetchMore,
    data,
  } = useLitters({
    page: 1,
    limit: 10,
  });
  const { data: partner, loading: loadingPartner } = useCurrentPartnerDetails();
  const isRoyalstart = partner?.partnerGroups?.includes("royalstart");
  const {
    items: littersRS,
    loading: loadingLittersRS,
  } = useLittersRoyalStart({ skip: !isRoyalstart });

  const litters = items.map(litter => ({
    ...litter,
    editUrl:
      partner && partner.isConv && !isLitterArchived(litter)
        ? `/litters/edit?id=${litter.id}`
        : undefined,
  }));

  const loading = loadingLitter || loadingPartner;
  const disableCreate = isRoyalstart;

  return (
    <>
      <LittersHeader loading={loading} disableCreate={disableCreate} />
      <WarningNoHKNumber />
      <LittersListIncoming
        loading={loadingLittersRS}
        litters={littersRS.map(litter => ({
          ...litter,
          total:
            litter.male + litter.female + (litter.genderUnspecifiedCount || 0),
        }))}
        onCompleteClick={litter => {
          navigateTo("/litters/add", { rsId: litter.id });
        }}
        BreedNameComponent={BreedName}
      />
      <Media when="desktop">
        <LittersList loading={loading} litters={litters} />
      </Media>
      <Media when="tablet">
        <LittersListMobile litters={litters} />
      </Media>
      <Pagination
        value={data?.litters.pageInfo?.page || 1}
        pages={
          pageInfo && pageInfo.limit
            ? Math.ceil(pageInfo.count / pageInfo.limit)
            : undefined
        }
        onChange={page => {
          fetchMore({
            variables: {
              page,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              return fetchMoreResult as ILitters;
            },
          });
        }}
        disabled={loading}
      />
    </>
  );
};

const LittersPage = () => (
  <Layout>
    <CheckPermission permissions={"litters"} redirect>
      <Litters />
    </CheckPermission>
  </Layout>
);

export default withPage(LittersPage);

const BreedName = ({
  type,
  breedCode,
}: {
  type?: string;
  breedCode?: string;
}) => {
  const { breed, loading: loading } = useLitterBreedByCode({
    type,
    code: breedCode?.toString(),
  });

  if (loading) {
    return (
      <Loader style={{ margin: 0, justifyContent: "initial" }} size={16} />
    );
  }

  return <span>{breed?.name}</span>;
};
