import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLocale } from "../locale";
import { Locale } from "../../../../../__generated__/globalTypes";
import { Breeds, BreedsVariables } from "./__generated__/Breeds";
import { UniversVariables, Univers } from "./__generated__/Univers";

const LitterBreedsQuery = gql`
  query Breeds($token: String!, $locale: Locale!, $type: String!) {
    breeds(token: $token, locale: $locale, type: $type) {
      result {
        id
        comId
        name
        delocalizedId
        univers {
          id
          name
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const useLitterBreeds = ({ type }: { type?: string } = {}) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Breeds, BreedsVariables>(
    LitterBreedsQuery,
    {
      skip: !token || !type,
      variables: {
        token,
        locale: Locale[locale],
        type: type || "",
      },
    },
  );

  return {
    ...res,
    data,
    items: data?.breeds.result || [],
  };
};

export const useLitterBreedByCode = ({
  type,
  code,
}: {
  type?: string;
  code?: string;
}) => {
  const { items: breeds, ...res } = useLitterBreeds({
    type,
  });
  const breed = breeds.find(b => b.comId?.toString() === code);
  return {
    ...res,
    breed,
  };
};

const LitterUniversQuery = gql`
  query Univers($token: String!, $locale: Locale!, $type: String!) {
    univers(token: $token, locale: $locale, type: $type) {
      result {
        id
        name
        delocalizedId
      }
      error {
        message
        code
      }
    }
  }
`;

export const useLitterUnivers = ({ type }: { type?: string } = {}) => {
  const token = useUserToken();
  const locale = useLocale();
  const { data, ...res } = useQuery<Univers, UniversVariables>(
    LitterUniversQuery,
    {
      skip: !token || !type,
      variables: {
        token,
        locale: Locale[locale],
        type: type || "",
      },
    },
  );

  return {
    ...res,
    data,
    items: data?.univers.result || [],
  };
};
